import React, { useState } from 'react'

import { Newsletter, Button } from './style'
import { useForm, UseFormMethods } from 'react-hook-form'
import axios from 'axios'
import * as URLS from 'src/config/api/Urls'
import * as Validations from 'inter-frontend-lib-util-form/lib/validations'
import useDomReady from 'src/hooks/window/useDomReady'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import { sendCDPFormData } from 'src/shared/helpers'

import ModalForm from './_modal/_index'
import { Modal } from 'src/components/Modal'

import AcceptTerms from 'src/components/AcceptTerms'

type SummitProps = {
  nome: string;
  email: string;
}

const GetTheBestOffers = () => {
  const [ sendDatalayerEvent ] = useDataLayer()

  const [ accept, setAccept ] = useState(false)
  const [ success, setSuccess ] = useState(false)
  const [ error, setError ] = useState(false)
  const [ loading, setLoading ] = useState(false)
  const { register, errors, handleSubmit }: UseFormMethods = useForm()
  const [ modal, setModal ] = useState(false)
  const [ name, setName ] = useState('')
  const [ email, setEmail ] = useState('')

  const formClean = () => {
    setName('')
    setEmail('')
  }

  const summit = async (data: SummitProps) => {
    setLoading(true)
    const formData = {
      ...data,
      campanha: 'inter travel',
      aceite: accept,
    }
      try {
        await axios.post(`${URLS.CONTACT_FORM_POST_V5}/lp-mktcloud-inter`, [ formData ])
        sendCDPFormData({ formName: 'Leads lp viagens', cpf: '', email: data.email })
        setError(false)
        setSuccess(true)
        setLoading(false)
        setModal(true)
        formClean()
      } catch (error) {
        setSuccess(false)
        setError(true)
        setLoading(false)
        setModal(true)
        formClean()
      }
  }

  const domReady = useDomReady()
  const modalForm = domReady && (
    <Modal isModalOpen={modal} setIsModalOpen={setModal} locationToRender={document.body}>
      <ModalForm closeModal={setModal} setError={setError} setSuccess={setSuccess} error={error} sent={success} />
    </Modal>
  )

  return (
    <section className='pb-5'>
      {modalForm}
      <div className='container'>
        <Newsletter>
          <div className='row d-flex justify-content-between'>
            <div className='col-12 col-md-5'>
              <span className='fs-12 lh-17 text-grayscale--500 fw-600 mb-4 d-md-block d-none'>FIQUE POR DENTRO</span>
              <h2 className='fs-24 lh-30 text-grayscale--500 fw-600 mb-4'>Cadastre-se e receba as melhores ofertas para viajar!</h2>
              <p className='fs-16 lh-19 text-grayscale--500 mb-4'>Fique por dentro das nossas ofertas e receba as novidades com exclusividade.</p>
            </div>
            <div className='col-12 col-md-6'>
              <form onSubmit={handleSubmit(summit)}>
                <div className='row'>
                  <div className='col-12 mb-4'>
                    <label className='fs-14 lh-17 fw-400  text-grayscale--500 d-block'>Nome</label>
                    <input
                      type='text'
                      name='nome'
                      id='nome'
                      value={name}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => setName(event.target.value)}
                      placeholder='Digite seu nome completo'
                      ref={register({
                      required: 'Por favor, digite seu nome completo',
                      validate: {
                        isName: (value: string) => Validations.name(value) || 'Por favor, digite seu nome completo',
                      },
                    })}
                    />
                    {errors.nome && <p className='fs-12 text-red--base mb-0 text-right'>{errors.nome.message}</p>}
                  </div>
                  <div className='col-12'>
                    <label className='fs-14 lh-17 fw-400  text-grayscale--500 d-block'>E-mail</label>
                    <input
                      name='email'
                      id='nome'
                      type='text'
                      value={email}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => setEmail(event?.target.value)}
                      placeholder='Digite seu e-mail'
                      ref={register({
                      required: 'Digite um e-mail válido',
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                        message: 'E-mail inválido',
                      },
                    })}
                    />
                    {errors.email && <p className='fs-12 text-red--base mb-0 text-right'>{errors.email.message}</p>}
                  </div>
                  <div className='col-12 mt-4'>
                    <AcceptTerms accept={accept} setAccept={setAccept} label='Estou de acordo com a ' />
                  </div>
                  <div
                    className='col-12 mt-3'
                    onClick={() => sendDatalayerEvent({
                      section: 'dobra_7',
                      section_name: 'Cadastre-se e receba as melhores ofertas para viajar!',
                      element_action: 'click button',
                      element_name: 'Receber as melhores ofertas',
                      c_page: window.location.href,
                    })}
                  >
                    <Button type='submit' disabled={!accept || loading}>{loading ? 'Enviando...' : 'Receber as melhores ofertas'}</Button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </Newsletter>
      </div>
    </section>
  )
}

export default GetTheBestOffers
