import React from 'react'

// Images
import Close from 'inter-frontend-svgs/lib/v2/navigation/close'
import successForm from '../../../assets/image/success.png'
import errorForm from '../../../assets/image/error.png'
// Styles
import { Container, CloseButton, Button } from './style'

type contactReceivablesForm = {
  closeModal: Function;
  setError: Function;
  setSuccess: Function;
  error: boolean;
  sent: boolean;
}

function ContactReceivablesForm ({ closeModal, setError, setSuccess, error, sent }: contactReceivablesForm) {
  const handleReturn = () => {
    setError(false)
    setSuccess(false)
    closeModal(false)
  }

  return (
    <>
      { error && (
        <Container className='container d-flex align-items-center'>
          <CloseButton className='border-0 bg-transparent pt-3' onClick={() => handleReturn()}>
            <Close width='24' height='24' color='orange--extra' />
          </CloseButton>
          <div className='col-11 mx-auto text-center'>
            <div className='mb-4'>
              <img src={errorForm} />

            </div>
            <h3 className='fs-20 lh-25 text-grayscale--500 fw-600'>Houve um erro por aqui</h3>
            <p className='fs-14 lh-17 text-grayscale--500'>E não foi possível criar o seu cadastro. Experimente voltar dentro de alguns instantes.</p>
            <Button onClick={() => handleReturn()}>Entendi</Button>
          </div>
        </Container>
      )}
      { sent && (
        <Container className='container sucesso d-flex align-items-center'>
          <CloseButton className='border-0 bg-transparent pt-4' onClick={() => handleReturn()}>
            <Close width='24' height='24' color='orange--extra' />
          </CloseButton>
          <div className='col-12 text-center'>
            <div className='mb-4'>
              <img src={successForm} />

            </div>
            <h3 className='fs-20 lh-25 text-grayscale--500 fw-600'>Seu cadastro foi confirmado</h3>
            <p className='fs-14 lh-17 text-grayscale--500'>Em breve, você receberá novidades e ofertas exclusivas do Inter no seu e-mail. Aproveite!</p>
            <Button onClick={() => handleReturn()}>Entendi</Button>
          </div>
        </Container>
      )}
    </>
  )
}

export default ContactReceivablesForm
