import styled from 'styled-components'

export const Section = styled.div`



background: #ffffff;

.summary {

  h4 {
    color: #161616 !important;

  }
  .arrow {
    width: 32px !important;
    height: 32px !important;
    background-color: #FFF2E7 !important;
    border-radius: 50% !important;
    background-position: center !important;
  }
}

.summary-content {
  padding-right: 0 !important;

  .col-1 {
    padding: 0 !important;
  }
}

.search-input {
  text-align: left;
  background: #F5F6FA !important;
  color: #B6B7BB !important;

  ::placeholder {
    color: #B6B7BB !important;
  }
}

.icon {
  position: absolute;
  right: 22px;
}

`
