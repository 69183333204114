import React from 'react'

import { Container, CloseButton } from './style'

// Icon
import OrangeDsIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'

type ModalProps = {
  isModal: boolean;
  setIsModal: Function;
  qrCode: string;
}

const Modal = ({ isModal, setIsModal, qrCode }: ModalProps) => {
  return (
    <Container isModal={isModal}>
      <CloseButton onClick={() => setIsModal(false)}>
        <OrangeDsIcon icon='exit' size='MD' color='#ff7a00' />
      </CloseButton>
      <div>
        <h3 className='fs-24 lh-30 text-grayscale--500 fw-600'>Acesse o <span className='text-orange--extra'>Super App </span>e comece a planejar sua próxima viagem! </h3>
        <hr />
        <p className='fs-16 lh-19 text-grayscale--400 fw-400'>As ofertas são exclusivas pra clientes Inter e você pode comprar pelo nosso Super App. Acesse, escolha seu destino e aproveite!</p>
        <div className='text-center'>
          <img src={qrCode} width={190} height={190} />
        </div>
        <hr />
        <p className='fs-16 lh-19 text-grayscale--400 fw-400'>Ligue a câmera do seu celular e aponte para o QR Code acima. Você será redirecionado para a loja de aplicativos. Aí é só baixar o app do Inter.</p>
      </div>
    </Container>
  )
}

export default Modal
