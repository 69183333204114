import React from 'react'

import { Section, Button, ButtonLink } from './style'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

type ModalProps = {
  setIsModal: Function;
}

const NaticionalAndInternacional = ({ setIsModal }: ModalProps) => {
  const [ sendDatalayerEvent ] = useDataLayer()

  const handleClick = () => {
    setIsModal(true)
    sendDatalayerEvent({
      section: 'dobra_3',
      section_name: 'Voos nacionais e internacionais',
      element_action: 'click button',
      element_name: 'Voar com mais cashback',
      c_page: window.location.href,
    })
  }
  return (
    <Section
      className='py-5 d-flex align-items-md-center align-items-end'
      role='img'
      aria-label='Avião ao fundo um céu azul'
    >
      <div className='container'>
        <div className='row d-flex justify-content-end '>
          <div className='col-12 col-md-6'>
            <span className='fs-12 lh-17 text-grayscale--500 fw-600 mb-4 d-md-block d-none'>PASSAGENS AÉREAS</span>
            <h2 className='fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 text-grayscale--500 fw-600 mb-4'>Voos nacionais e internacionais</h2>
            <p className='fs-16 lh-19 fs-md-18 lh-md-22 text-grayscale--400'>Passagens aéreas para destinos brasileiros e em todo o mundo com as melhores condições de compra e muito cashback.</p>
            <Button onClick={() => handleClick()} className='d-none d-md-block'>Voar com mais cashback</Button>
            <ButtonLink
              className='d-md-none'
              href='https://intergo.app/aaeb73bf'
              target='_blank'
              rel='noreferrer'
              onClick={() => sendDatalayerEvent({
                section: 'dobra_3',
                section_name: 'Voos nacionais e internacionais',
                element_action: 'click button',
                element_name: 'Voar com mais cashback',
                c_page: window.location.href,
              })}
            >
              Voar com mais cashback
            </ButtonLink>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default NaticionalAndInternacional
