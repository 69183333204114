import styled from 'styled-components'
import { breakpoints } from 'src/styles/breakpoints'

export const Newsletter = styled.div`
    border-radius: 16px;
    background: #F5F6FA;
    padding: 44px 24px 27px;
    margin-top: 54px;

    @media (min-width: ${breakpoints.md}){
      padding: 44px 26px 29px;
    }

    @media (min-width: ${breakpoints.lg}){
      padding: 44px 37px 39px;
      margin-top: 42px
    }
    @media (min-width: ${breakpoints.xl}){
      padding: 44px 44px 39px;
      margin-top: 54px
    }

  input{
    border-radius: 8px;
    width: 100%;
    height: 40px;
    background: #fff;
    border: none;
    padding: 16px;
    color: #161616;
    font-size: 14px;
    line-height: 17px;

    &::placeholder{
      font-size: 14px;
      line-height: 17px;
      color: #B6B7BB;
      font-weight: 600;
    }

    &:focus{
      outline: none;
    }

  }
  @media (min-width: ${breakpoints.lg}){
    .form-label-check{
      display: flex;
      align-items: center;
      padding-left: 0;

      a{
        margin: 0 3px;
      }

      &::before{
        position: static;
        margin-right: 10px;
      }
    }

  }


`
export const Button = styled.button`
    border-radius: 8px;
    background: #ff7a00;
    width: 100%;
    height: 40px;
    font-size: 14px;
    line-height: 17px;
    color: #fff;
    border: none;
    font-weight: 600;

    &:focus{
      outline: none;
    }
`
