import styled from 'styled-components'
import { orange, white } from 'src/styles/colors'
import { breakpoints } from 'src/styles/breakpoints'

type SectionProps = {
  sm: string;
  md: string;
  lg: string;
  xl: string;
}

export const Section = styled.section`
  background-position: right ;
  background: url(${(props: SectionProps) => props.sm });
  background-repeat: no-repeat;
  min-height: 694px;
  background-size:100%;

  @media (min-width: ${breakpoints.md}){
    background: url(${(props: SectionProps) => props.md });
    background-repeat: no-repeat;
    background-position: right ;
    min-height: 339px;
    background-size: 100%;
  }

  @media (min-width: ${breakpoints.lg}){
    background: url(${(props: SectionProps) => props.lg });
    background-repeat: no-repeat;
    background-position: right ;
    min-height: 456px;
  }

  @media (min-width: ${breakpoints.xl}){
    background: url(${(props: SectionProps) => props.xl });
    background-repeat: no-repeat;
    background-position: right ;
    min-height: 480px;
    background-size:100% ;
  }
`
export const Button = styled.a`
  background: #ff7a00;
  border-radius: 8px;
  color: #fff;
  width: 100%;
  font-size: 14px;
  line-height: 17px;
  font-weight: 600;
  height: 48px;
  border: none;
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:focus, &:hover{
    outline: none;
    color: #fff;
    opacity: 0.9;
    text-decoration: none;
  }
`
export const CarouselContent = styled.div`
  .carousel {
    padding-bottom: 34px;
  }

  .react-multi-carousel-dot-list{
    max-width:400px;
  }

  .react-multi-carousel-dot{
    button{
      background: ${white};
      border: 1px solid ${orange.extra};
    }
  }

  .react-multi-carousel-dot--active{
    button{
      background: ${orange.extra};
    }
  }

  @media (min-width: ${breakpoints.md}){
    .react-multiple-carousel__arrow--right{
      left:calc(50% + 250px);
      right:auto !important;
    }

    .react-multi-carousel-dot-list{
      left:50%;
      transform:translateX(-50%);
      bottom: 4px;
    }

    .react-multiple-carousel__arrow--left{
      left:calc(50% - 250px);
    }
  }
`
export const Circle = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: #fff;
  outline: none;
  border: none;
  padding: 0;

  &:focus {
    outline: none;
  }

  &.left {
    left: 22px;
    display: none;

    @media (min-width: ${breakpoints.md}) {
      display: block;
    }
  }

  &.right {
    right: 22px;
    display: none;

    @media (min-width: ${breakpoints.md}) {
      display: block;
    }
  }
`
