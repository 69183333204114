import styled from 'styled-components'
import { breakpoints } from 'src/styles/breakpoints'

type CardProps = {
  partner: string;
}

export const Section = styled.section`
    background-color: #ff7a00;
    min-height: 678px;

    @media (min-width: ${breakpoints.md}){
      min-height: 600px;
    }

    @media (min-width: ${breakpoints.lg}){
      max-height: 645px;
    }

    @media (min-width: ${breakpoints.xl}){
      max-height: 716px;
    }

    .react-multi-carousel-dot  button{
      background-color: #ffffff !important;
    }



`
export const Button = styled.button`
      background: #fff;
      border: none;
      border-radius: 8px;
      width: 100%;
      color: #ff7a00;
      font-size: 12px;
      line-height: 15px;
      font-weight: 600;
      height: 40px;
      margin-top: 32px;
      box-shadow: 0px 4px 8px -2px rgba(22, 22, 22, 0.08);


      @media (min-width: ${breakpoints.md}){
        max-width: 255px;
      }

      @media (min-width: ${breakpoints.lg}){
        margin-top: 7px;

      }



      &:focus{
        outline: none;
      }


`

export const Card = styled.div`
    border: 1px solid #F5F6FA;
    border-radius: 8px;
    background: #FFFFFF;
    width: 100%;
    height: 101px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    box-shadow: 0px 4px 8px -2px rgba(22, 22, 22, 0.08);
    overflow:hidden;
    padding: 5px 5px 27px;

    img{
      width: 100%;
      object-fit: contain;
      height: 100%;
    }

    @media (min-width: ${breakpoints.lg}){
      height: 154px;
      padding: 5px 5px 43px;
    }

    @media (min-width: ${breakpoints.xl}){
      height: 187px;
    }

    .card-footer{
      background-color: #E9F8F0;
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 25px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius:0 0 8px 8px;

      @media (min-width: ${breakpoints.lg}){
        height: 41px;
        background-color: #E9F8F0;
      }


    .text{
      color: #00AA4F;
      font-size: 12px;
      line-height: 15px;
      font-weight: 600;



    }
    }
    .hilton{
      width: 88px;

      @media (min-width: ${breakpoints.xl}){
        width: 110px;
      }
    }


`
export const ButtonCard = styled.button`
    border: 1px solid #F5F6FA;
    border-radius: 8px;
    background: #FFFFFF;
    width: 100%;
    height: 101px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    box-shadow:0px 4px 8px -2px rgba(22, 22, 22, 0.08);

      img{
        width: 70%;
      }


    &:focus{
      outline: none;
    }

    @media (min-width: ${breakpoints.lg}){
      height: 154px;
    }


    @media (min-width: ${breakpoints.xl}){
      height: 187px;
    }


`
export const ButtonCardLink = styled.a`
    border: 1px solid #F5F6FA;
    border-radius: 8px;
    background: #FFFFFF;
    width: 100%;
    height: 101px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;

      img{
        width: 70%;
      }


    &:focus, &:hover{
      outline: none;
      text-decoration: none;
    }

    @media (min-width: ${breakpoints.lg}){
      height: 155px;
    }


`
export const ButtonLink = styled.a`
    background: #fff;
      border: none;
      border-radius: 8px;
      width: 100%;
      color: #ff7a00;
      font-size: 12px;
      line-height: 15px;
      font-weight: 600;
      height: 40px;
      margin-top: 32px;
      box-shadow: 0px 4px 8px -2px rgba(22, 22, 22, 0.08);
      display:flex;
      align-items: center;
      justify-content:center;


      @media (min-width: ${breakpoints.md}){
        max-width: 255px;
      }

      @media (min-width: ${breakpoints.lg}){
        margin-top: 7px;

      }



      &:focus{
        outline: none;
      }


`
