import React from 'react'
import Carousel, { ArrowProps } from 'react-multi-carousel'

import { Section, CarouselContent, Circle } from './style'

import Insurance from 'src/assets/data/inter-travel/inter-travel.json'
import { OrangeIcon } from 'src/components/UI/MarkdownIcon'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

type InsuranceProps = {
  link: string;
  imagemSm: ImageProps;
  imagemMd: ImageProps;
  imagemLg: ImageProps;
  imagemXl: ImageProps;
  tag: string;
}

type ImageProps = {
  url: string;
  name: string;
  alt: string;
}

const devices = {
  desktop: {
    breakpoint: {
      max: 4000,
      min: 1024,
    },
    items: 1,
    partialVisibilityGutter: 250,
  },
  tablet: {
    breakpoint: {
      max: 1023,
      min: 465,
    },
    items: 1,
  },
  mobile: {
    breakpoint: {
      max: 464,
      min: 0,
    },
    items: 1,
  },
}

const ArrowRight = ({ onClick }: ArrowProps) => (
  <Circle className='right' onClick={onClick} type='button'>
    <OrangeIcon color='#FF7A00' icon='chevron-right' size='MD' />
  </Circle>
)

const ArrowLeft = ({ onClick }: ArrowProps) => (
  <Circle className='left' onClick={onClick} type='button'>
    <OrangeIcon color='#FF7A00' icon='chevron-left' size='MD' />
  </Circle>
)

const TravelInsurance = () => {
  const [ sendDatalayerEvent ] = useDataLayer()

  const handleClickTag = (item: InsuranceProps) => {
    sendDatalayerEvent({
      section: 'dobra_9',
      section_name: 'banner',
      element_action: 'click banner',
      element_name: item.tag,
      c_page: window.location.href,
      redirect_url: `${item.link}?tag=${item.tag}`,
    })
  }

  return (
    <CarouselContent>
      <Carousel
        className='carousel'
        customRightArrow={<ArrowRight />}
        customLeftArrow={<ArrowLeft />}
        responsive={devices}
        transitionDuration={1000}
        autoPlay
        showDots
        infinite
      >
        {
          Insurance.map((item: InsuranceProps) => (
            <a
              key={item.link}
              href={`${item.link}?tag=${item.tag}`}
              target='_blank'
              rel='noreferrer'
              onClick={() => handleClickTag(item)}
            >
              <Section
                className='py-5 d-flex justify-content-between align-items-end'
                sm={item.imagemSm.url}
                md={item.imagemMd.url}
                lg={item.imagemLg.url}
                xl={item.imagemXl.url}
              />
            </a>
          ))
        }
      </Carousel>
    </CarouselContent>
  )
}

export default TravelInsurance
