import React, { useState } from 'react'

import Layout from 'src/layouts/BaseLayout'

import pageContext from './pageContext.json'

import Hero from './_sections/_hero/_index'
import YouPlanYourTrip from './_sections/_you-plan-your-trip/_index'
import NaticionalAndInternacional from './_sections/_national-and-international/_index'
import AccommodationForEveryone from './_sections/_accommodation-for-everyone/_index'
import MoreConfort from './_sections/_more-confort/_index'
import IncrediblePartnerships from './_sections/_incredible-partnerships/_index'
import GetTheBestOffers from './_sections/_get-the-best-offers/_index'
import Blog from './_sections/_blog/_index'
import TravelInsurance from './_sections/_travel-insurance/_index'
import FaqInterTravel from './_sections/_faq/_index'
import QrCode from './assets/image/qr-code-viagens.jpg'

import { Modal } from 'src/components/Modal'
import HomeApp from './_components/_modal/_index'
import useDomReady from 'src/hooks/window/useDomReady'

const InterTravel = () => {
  const [ isModal, setIsModal ] = useState(false)

  const domReady = useDomReady()
  const homeApp = domReady && (
    <Modal isModalOpen={isModal} setIsModalOpen={setIsModal} locationToRender={document.body}>
      <HomeApp isModal={isModal} setIsModal={setIsModal} qrCode={QrCode} />
    </Modal>
  )

  return (
    <Layout pageContext={pageContext}>
      {homeApp}
      <Hero setIsModal={setIsModal} />
      <YouPlanYourTrip setIsModal={setIsModal} />
      <NaticionalAndInternacional setIsModal={setIsModal} />
      <AccommodationForEveryone setIsModal={setIsModal} />
      <MoreConfort setIsModal={setIsModal} />
      <IncrediblePartnerships setIsModal={setIsModal} />
      <GetTheBestOffers />
      <Blog />
      <TravelInsurance />
      <FaqInterTravel />
    </Layout>
  )
}

export default InterTravel
