import React, { useLayoutEffect, useState, useEffect } from 'react'
import useWidth from 'src/hooks/window/useWidth'
import axios from 'axios'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import { Section, Button, Card, ButtonCard, ButtonCardLink, ButtonLink } from './style'

import icredible from '../../assets/data/incredible.json'
import eMuitoMais from '../../assets/image/e-muito-mais.png'

import OrangeDsIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'
import DefaultCarousel from 'src/components/UI/Carousels/DefaultCarousel'

type incredibleProps = {
  icon: string;
  description: string;
}

type partnetshipsProps = {
  idParceiro: string;
  tarja: string;
  imagem: string;
  alt: string;
  link: string;
  imageUrl: string;
  id: string;
  fullCashback: string;
  name: string;
  slug: string;
}

type ModalProps = {
  setIsModal: Function;
}

const ListPartners = [
  'booking',
  'movida',
  'viator',
  'buser',
  'clickbus',
  'hoteiscom',
  'tap',
]

function SelectPartner (array: partnetshipsProps[]) {
 const newList = array.filter((item: partnetshipsProps) => {
      const selected = ListPartners.find((partner: string) => item.slug === partner)
      return selected
    })
    newList.push({
      idParceiro: 'button',
      tarja: '',
      imagem: '',
      alt: '',
      link: '',
      imageUrl: '',
      id: '',
      fullCashback: '',
      name: '',
      slug: '',
    })
    return newList
}

const WIDTH_MD = 768

const IncrediblePartnerships = ({ setIsModal }: ModalProps) => {
  const [ sendDatalayerEvent ] = useDataLayer()
  const [ isMobile, setIsMobile ] = useState(true)
  const [ partnerData, setPartnerData ] = useState<partnetshipsProps[]>([])
  const width = useWidth(300)

  const handleClickShop = (item: string) => {
    sendDatalayerEvent({
      section: 'dobra_6',
      section_name: 'Parcerias incríveis para mais cashback',
      element_action: 'click banner',
      element_name: item,
      c_page: window.location.href,
      redirect_url: `https://shopping.inter.co/lojas/${item}`,
    })
  }

  const handleClick = () => {
    setIsModal(true)
    sendDatalayerEvent({
      section: 'dobra_6',
      section_name: 'Parcerias incríveis para mais cashback',
      element_action: 'click button',
      element_name: 'Conhecer sites parceiros',
      c_page: window.location.href,
    })
  }

  useEffect(() => {
     const requestApi = async () => {
       const response = await axios.get('https://marketplace-api.web.bancointer.com.br/site/affiliate/inter/v1/departments/VIAGEM/stores?lang=pt-BR')
       setPartnerData(SelectPartner(response.data))
     }
     requestApi()
  }, [])

  useLayoutEffect(() => {
    setIsMobile(WIDTH_MD > width)
  }, [ width ])

  return (
    <Section className='py-5'>
      <div className='container'>
        <div className='row d-flex align-items-center align-items-lg-start'>
          <div className='col-12 col-md-6'>
            <span className='fs-12 lh-17 text-white fw-600 mb-4 d-md-block d-none'>MAIS PARA A SUA VIAGEM</span>
            <h2 className='fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 text-white fw-600 mb-4'>Parcerias incríveis para mais cashback</h2>
            <p className='fs-16 lh-19 fs-md-18 lh-md-22 text-white mb-4'>São mais de 30 sites parceiros para tornar sua <span className='d-block'> viagem ainda mais completa e garantir cashback <span className='d-block'>direto na sua conta.</span> </span></p>
            {
              icredible.map((item: incredibleProps) => (
                <div className='d-flex mb-4' key={item.icon}>
                  <OrangeDsIcon icon={item.icon} size='MD' color='#ffffff' />
                  <p className='fs-16 lh-19 text-white ml-3 mb-0'>{item.description}</p>
                </div>
              ))
            }
            <Button
              onClick={() => handleClick()}
              className='d-none d-md-block'
            >
              Conhecer sites parceiros
            </Button>
            <ButtonLink
              className='d-md-none'
              href='https://intergo.app/aaeb73bf'
              target='_blank'
              rel='noreferrer'
              onClick={() => sendDatalayerEvent({
                section: 'dobra_6',
                section_name: 'Parcerias incríveis para mais cashback',
                element_action: 'click button',
                element_name: 'Conhecer sites parceiros',
                c_page: window.location.href,
              })}
            >
              Conhecer sites parceiros
            </ButtonLink>
          </div>

          <div className='col-12 col-md-6'>
            {
              isMobile ? (
                <DefaultCarousel sm={{ items: 2 }} customColor='white'>
                  {
                    partnerData.map((item: partnetshipsProps) => (
                      <>
                        {
                          item.idParceiro === 'button' ? (
                            <div className='px-1'>
                              <ButtonCardLink
                                href='https://intergo.app/0dab4dff'
                                target='_blank'
                                rel='noreferrer'
                                onClick={() => handleClickShop(item.slug)}
                              >
                                <img src={eMuitoMais} />
                              </ButtonCardLink>
                            </div>
                          ) : (
                            <a href={`https://shopping.inter.co/lojas/${item.slug}`} target='_blank' rel='noreferrer' key={item.idParceiro} onClick={() => handleClickShop(item.slug)}>
                              <div className='px-1'>
                                <Card>
                                  <img src={item.imageUrl} className={item.id} alt={item.slug} />
                                  <div className='card-footer'>
                                    <span className='text'>{item.fullCashback}</span>
                                  </div>
                                </Card>
                              </div>
                            </a>
                          )
                        }
                      </>
                    ))
                   }
                </DefaultCarousel>
              ) : (
                <div className='row d-flex align-items-start mt-lg-n5'>
                  <div className='col-6 mt-lg-n5'>
                    <div className='row'>
                      {
                        partnerData.slice(0, 4).map((item: partnetshipsProps) => (
                          <div className='col-12 mb-3' key={item.id} onClick={() => handleClickShop(item.slug)}>
                            <a href={`https://shopping.inter.co/lojas/${item.slug}`} target='_blank' rel='noreferrer'>
                              <Card>
                                <img src={item.imageUrl} alt={item.slug} />
                                <div className='card-footer'>
                                  <span className='text'>{item.fullCashback}</span>
                                </div>
                              </Card>
                            </a>
                          </div>
                        ))
                      }
                    </div>
                  </div>
                  <div className='col-6 mt-xl-n4 mt-n4 mt-lg-4'>
                    <div className='row'>
                      {
                        partnerData.slice(-4).map((item: partnetshipsProps) => (
                          <>
                            {item.idParceiro === 'button' ? null : (
                              <div className='col-12 mb-3' key={item.id}>
                                <a href={`https://shopping.inter.co/lojas/${item.slug}`} target='_blank' rel='noreferrer' onClick={() => handleClickShop(item.slug)}>
                                  <Card>
                                    <img src={item.imageUrl} alt={item.slug} className={item.id} />
                                    <div className='card-footer'>
                                      <span className='text'>{item.fullCashback}</span>
                                    </div>
                                  </Card>
                                </a>
                              </div>
                            )}
                          </>
                        ))
                      }
                      <div className='col-12'>
                        <ButtonCard onClick={() => handleClick()}>
                          <img src={eMuitoMais} />
                        </ButtonCard>
                      </div>
                    </div>
                  </div>
                </div>
              )
            }
          </div>
        </div>
      </div>
    </Section>
  )
}

export default IncrediblePartnerships
