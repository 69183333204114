import styled from 'styled-components'
import { breakpoints } from 'src/styles/breakpoints'

export const Section = styled.section`
  background: url('https://central-imagens.bancointer.com.br/images-without-small-versions/malas-xs-viagens/image.webp');
  background-repeat: no-repeat;
  background-size: contain;
  min-height: 635px;
  background-position: top;

  @media (min-width: ${breakpoints.md}){
    background: url('https://central-imagens.bancointer.com.br/images-without-small-versions/malas-md-viagens/image.webp');
    background-repeat: no-repeat;
    background-size: 387px;
    background-position: center right;
    min-height: 420px;
  }

  @media (min-width: ${breakpoints.lg}){
    background: url('https://central-imagens.bancointer.com.br/images-without-small-versions/malas-lg-viagens/image.webp');
    background-repeat: no-repeat;
    background-size: 500px;
    background-position: right;
  }

  @media (min-width: ${breakpoints.xl}){
    background: url('https://central-imagens.bancointer.com.br/images-without-small-versions/malas-xl-viagens/image.webp');
    background-repeat: no-repeat;
    background-size: 719px;
    background-position: right;
    min-height: 476px;
  }
`

export const Button = styled.button`
  background: #ff7a00;
  width: 100%;
  border-radius: 8px;
  border: none;
  color: #fff;
  font-size: 12px;
  line-height: 17px;
  font-weight: 600;
  height: 40px;
  margin-top: 18px;

  @media (min-width: ${breakpoints.md}){
    margin-top: 32px;
    max-width: 239px;
  }
  @media (min-width: ${breakpoints.lg}){
    margin-top: 32px;
    max-width: 296px;
  }
  @media (min-width: ${breakpoints.xl}){
    max-width: 360px;
  }

  &:focus{
    outline: none;
  }
`
export const ButtonLink = styled.a`
  background: #ff7a00;
  width: 100%;
  border-radius: 8px;
  border: none;
  color: #fff;
  font-size: 12px;
  line-height: 17px;
  font-weight: 600;
  height: 40px;
  margin-top: 18px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: ${breakpoints.md}){
    margin-top: 32px;
    max-width: 239px;

  }

  @media (min-width: ${breakpoints.lg}){
    margin-top: 32px;
    max-width: 296px;

  }

  @media (min-width: ${breakpoints.xl}){
    max-width: 360px;
  }

  &:focus, &:hover{
    outline: none;
    text-decoration: none;
    color: #fff;
    opacity: 0.9;
  }
`
