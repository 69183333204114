import styled from 'styled-components'
import { white } from 'src/styles/colors'
import { breakpoints } from 'src/styles/breakpoints'

export const Container = styled.div`
  background: ${white};
  padding: 20px 10px;
  position: relative;
  width: 312px;
  height: 423px;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 8px 8px 0px 0px;

  @media (min-width: ${breakpoints.md}) {
    box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    min-height: 406px;
    max-width: 410px;
    padding-left: 4px;
    padding-right: 4px;
  }

  .bt-green-dark{
    max-width: 220px;
  }

  .form--default {
    height: 90vh;
    overflow: auto;

    @media (min-width: ${breakpoints.md}) {
      height: 77vh;
    }
  }
`

export const CloseButton = styled.button`
  position: absolute;
  right: 25px;
  top: 0;
  z-index: 10;
`
export const Button = styled.button`
   background: #ff7a00;
   border-radius: 8px;
   width: 212px;
   height: 48px;
   font-size: 14px;
   line-height: 17px;
   color: #fff;
   font-weight: 600;
   border: none;
   margin-top: 32px;

   &:focus{
     outline: none;
   }
`
