import React, { useState, useLayoutEffect } from 'react'
import { format, parseISO, isBefore } from 'date-fns'

import { Section, BlogItem } from './style'

import postsBlog from 'src/assets/data/inter-travel/posts-blog-inter-travel.json'

import DefaultCarousel from 'src/components/UI/Carousels/DefaultCarousel'
import useWidth from 'src/hooks/window/useWidth'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

type BlogProps = {
  link: string;
  title: string;
  category_name: string;
  grid_image_url: string;
  introduction: string;
  id: string;
  subcategory_name: string;
  created_at: string;
  updated_at: string;
}

const ordenate = (list: BlogProps[]) => {
 const order = list.sort((a: BlogProps, b: BlogProps) => {
    const aDate = parseISO(a.created_at)
    const bDate = parseISO(b.created_at)
    return isBefore(aDate, bDate) ? 1 : -1
  })
  return order
}
const WIDTH_MD = 768

const Blog = () => {
  const [ isMobile, setIsMobile ] = useState(true)
  const width = useWidth(300)
  const [ sendDatalayerEvent ] = useDataLayer()

  const handleClickTag = (item: string) => {
    sendDatalayerEvent({
      section: 'dobra_8',
      section_name: 'BLOG DO INTER',
      element_action: 'click banner',
      element_name: item,
      c_page: window.location.href,
      redirect_url: `https://blog.inter.co${item}`,
    })
  }

  useLayoutEffect(() => {
     setIsMobile(WIDTH_MD > width)
  }, [ width ])

  return (
    <Section className='py-5'>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <div className='d-flex align-items-center mb-5'>
              <span className='fs-12 lh-15  fw-700 text-grayscale--500'>BLOG DO INTER</span>
            </div>
            <div className='row'>
              {
                isMobile ? (
                  <div className='col-12'>
                    <DefaultCarousel sm={{ items: 1 }}>
                      {
                      ordenate(postsBlog)
                      .slice(0, 4)
                      .map((item: BlogProps) => (
                        <div key={item.id}>
                          <BlogItem>
                            <a
                              href={`https://blog.inter.co${item.link}`} target='_blank'
                              rel='noreferrer' onClick={() => handleClickTag(item.link)}
                            >
                              <img src={item.grid_image_url} alt={item.title} />
                              <header>{item.title}</header>
                            </a>
                            <p>{item.introduction}</p>
                            <footer>{item.category_name}  &bull; {format(parseISO(item.updated_at), 'dd/MM/yy')}</footer>
                          </BlogItem>
                        </div>
                    ))
                    }
                    </DefaultCarousel>
                  </div>
                ) : (
                  <>
                    {
                    ordenate(postsBlog)
                      .slice(0, 4)
                      .map((item: BlogProps) => (
                        <div className='col-12 col-md-6 col-lg-3' key={item.id}>
                          <BlogItem>
                            <a
                              href={`https://blog.inter.co${item.link}`} target='_blank'
                              rel='noreferrer' onClick={() => handleClickTag(item.link)}
                            >
                              <img src={item.grid_image_url} alt={item.title} />
                              <header>{item.title}</header>
                            </a>
                            <p>{item.introduction}</p>
                            <footer>{item.category_name}  &bull; {format(parseISO(item.updated_at), 'dd/MM/yy')}</footer>
                          </BlogItem>
                        </div>
                    ))
                  }
                  </>
                )
              }
            </div>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default Blog
