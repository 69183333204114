import styled from 'styled-components'
import { breakpoints } from 'src/styles/breakpoints'

export const Section = styled.section`
  min-height: 634px;
  background: url('https://central-imagens.bancointer.com.br/images-without-small-versions/hero-xs-viagens/image.webp');
  background-repeat: no-repeat;
  background-size: 100%;
  background-position-y:-137px ;

  @media (min-width: ${breakpoints.md}){
    background: url('https://central-imagens.bancointer.com.br/images-without-small-versions/hero-md-viagens/image.webp');
    background-repeat: no-repeat;
    background-size: cover;
    min-height:765px;
  }

  @media (min-width: ${breakpoints.lg}){
    background: url('https://central-imagens.bancointer.com.br/images-without-small-versions/hero-lg-viagens/image.webp');
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 846px;
  }

  @media (min-width: ${breakpoints.xl}){
    background: url('https://central-imagens.bancointer.com.br/images-without-small-versions/hero-xl-viagens/image.webp');
    background-repeat: no-repeat;
    background-size: 100%;
    min-height: 768px;
  }

  @media (min-width : ${breakpoints.xxl}){
    background: url('https://central-imagens.bancointer.com.br/images-without-small-versions/hero-xl-viagens/image.webp');
    background-repeat: no-repeat;
    background-size: 100%;
    background-position-y:-176px;
    min-height:calc( 100vh - 112px);

  }
`

export const Button = styled.button`
  width: 100%;
  height: 50px;
  border-radius: 8px;
  background: #ff7a00;
  color: #fff;
  font-size: 14;
  line-height: 17px;
  font-weight: 600;
  border: none;
  margin-top: 25px;

  @media (min-width: ${breakpoints.md}){
    max-height: 255px;
    margin-top: 40px;
    max-width: 255px;
  }

  &:focus{
    outline: none;
  }
`
export const ButtonLink = styled.a`
  width: 100%;
  height: 50px;
  border-radius: 8px;
  background: #ff7a00;
  color: #fff;
  font-size: 14;
  line-height: 17px;
  font-weight: 600;
  border: none;
  margin-top: 25px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: ${breakpoints.md}){
    max-height: 255px;
    margin-top: 40px;
  }

  &:focus, &:hover{
    outline: none;
    text-decoration: none;
    color: #fff;
    opacity: 0.9;
  }
`
