import styled from 'styled-components'
import { breakpoints } from 'src/styles/breakpoints'
import BgMd from '../../assets/image/more-confort-md.png'

export const Section = styled.section`
  background-color: #F5F6FA;
  min-height: 591px;
  display: flex;
  align-items: center;
  background: url('https://central-imagens.bancointer.com.br/images-without-small-versions/dobra5-xs-viagens/image.webp');
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: 30px;

  @media (min-width: ${breakpoints.md}){
    min-height: 469px;
    background: url('https://central-imagens.bancointer.com.br/images-without-small-versions/dobra5-md-viagens/image.webp');
    background-repeat: no-repeat;
    background-position-x: 30px;
    background-position-y: center;
    background-color: #F5F6FA;
  }

  @media (min-width: ${breakpoints.lg}){
    min-height: 583px;
    background: url('https://central-imagens.bancointer.com.br/images-without-small-versions/dobra5-lg-viagens/image.webp');
    background-repeat: no-repeat;
    background-position-y: center;
    background-position-x: 30px;
    background-color: #F5F6FA;
  }

  @media (min-width: ${breakpoints.xl}){
    background: url('https://central-imagens.bancointer.com.br/images-without-small-versions/dobra5-xl-viagens/image.webp');
    min-height: 516px;
    background-repeat: no-repeat;
    background-size: 624px;
    background-position-y: center;
    background-position-x: 0px;
    background-color: #F5F6FA;
  }
`

export const Button = styled.button`
  background: #ff7a00;
  border: none;
  border-radius: 8px;
  width: 100%;
  color: #fff;
  font-size: 12px;
  line-height: 15px;
  font-weight: 600;
  height: 40px;
  margin-top: 32px;

  @media (min-width: ${breakpoints.md}){
    max-width: 228px;
  }

  @media (min-width: ${breakpoints.lg}){
    margin-top: 24px;
    max-width: 295px;
  }

  @media (min-width: ${breakpoints.xl}){
    max-width: 360px;
  }

  &:focus{
    outline: none;
  }
`
export const ButtonLink = styled.a`
  background: #ff7a00;
  border: none;
  border-radius: 8px;
  width: 100%;
  color: #fff;
  font-size: 12px;
  line-height: 15px;
  font-weight: 600;
  height: 40px;
  margin-top: 32px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: ${breakpoints.md}){
    max-width: 228px;
  }

  @media (min-width: ${breakpoints.lg}){
    margin-top: 24px;
    max-width: 295px;
  }

  @media (min-width: ${breakpoints.xl}){
    max-width: 360px;
  }

  &:focus, &:hover{
    outline: none;
    text-decoration: none;
    opacity: 0.9;
    color: #fff;

  }
`
