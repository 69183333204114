import styled, { keyframes, css } from 'styled-components'
import { grayscale, white } from 'src/styles/colors'
import { breakpoints } from 'src/styles/breakpoints'

type ContainerProps = {
  isModal: boolean;
}

const animatedModal = keyframes`
  from {
    right: -100%;
  }
  to {
    right: 0;
  }
`

export const Container = styled.div<ContainerProps>`
  align-items: center;
  background-color: ${white};
  overflow-y: auto;
  display: block;
  height: 100%;
  display: flex;
  top: 0;
  bottom: 0;
  right: 0;
  position: fixed;
  transition: 0.5s ease-in-out;
  animation: ${animatedModal} 0.5s ease-in-out forwards;

    h3 {
      font-family: 'Sora';
    }

    ${(props: ContainerProps) => props.isModal && (
    css`
      min-height: 100vh;
      padding: 30px 24px;
      width: 100%;

      @media (min-width: ${breakpoints.md}) {
        max-width: 596px;
        padding: 69px 127px;
      }
    `
  )}

  .form--default {
    label {
      color: ${grayscale[400]};
      margin-bottom: 4px;
    }

    .form-label-check {
      &::before {
        top: 34px;

        @media (min-width: ${breakpoints.md}) {
          top: 26px;
        }
      }
    }
    .form-input-check {
      &:checked ~ label {
      &::after {
        top: 37px;
          @media (min-width: ${breakpoints.md}) {
            top: 29px;
          }
        }
      }
    }
  }

  &.sent {
    @media (min-width: ${breakpoints.md}) {
      padding-left: 70px;
      padding-right: 70px;
    }
  }

  &.under-age {
    img {
      max-width: 204px;
    }
  }

  &.filled {
    @media (min-width: ${breakpoints.md}) {
      padding-left: 80px;
      padding-right: 80px;
    }
  }

  .center-content{
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 100%;
  }
`

export const CloseButton = styled.div`
  position: absolute;
  background: none;
  border: none;
  top:30px;
  cursor: pointer;
  right: 30px;

  &:focus{
    outline: none;
  }
`
